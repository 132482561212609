import StoryChief from '@/storychief';

/**
 * @param {string} event
 * @param {object} properties
 */
export default function analyticsTrack(event, properties = undefined) {
  if (window.analytics) {
    const defaultProperties = {
      trial: StoryChief.account.trial,
    };

    window.analytics.track(
      event,
      { ...defaultProperties, ...properties },
      { groupId: StoryChief.account.id, active: true },
    );
  }
}
